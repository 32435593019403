<template>
    <div class="col-md-4 col-sm-6 col-12 kb-search-content">
        <base-card-plain >
            <template #default>
            <a href="page-kb-category.html">
                <base-image
                    :imgName="applicantAvatar"
                    imgGeo="illustration"
                    :imgAlt="applicantName + 'in Talent pool'"
                    imgClass="card-img-top"
                    >
                </base-image>

                <div class="card-body text-center">
                    <h4>{{applicantName}}</h4>
                    <p class="text-body mt-1 mb-0">
                        View JD for which applicant was selected
                    </p>
                </div>
            </a>
            </template>
        </base-card-plain>
    </div>

</template>

<script>
export default{
    props:{
        applicantName:{
            type:String,
            required:false
        },
        applicantAvatar:{
            type:String,
            required:false
        },
        jobTitle:{
            type:String,
            required:false
        }
    },
    computed:{
        applicantFinalAvatar(){
            return require('../../assets/images/illustration/'+this.applicantAvatar);
        }
    }
}


</script>
