<template>

    <!-- Knowledge base -->
    <section id="knowledge-base-content">
        <div class="row kb-search-content-info match-height">
            <!-- recruitment-load-test-item card -->
            <recruitment-load-test-item
                applicantName="Sipi Ankumarh"
                applicantAvatar="sales.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <recruitment-load-test-item
                applicantName="John Amos"
                applicantAvatar="marketing.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <recruitment-load-test-item
                applicantName="Jane Kennedy"
                applicantAvatar="api.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <recruitment-load-test-item
                applicantName="Sipi Ankumarh"
                applicantAvatar="sales.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <recruitment-load-test-item
                applicantName="John Amos"
                applicantAvatar="marketing.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <recruitment-load-test-item
                applicantName="Jane Kennedy"
                applicantAvatar="api.svg"
                jobTitle="None"
                >
            </recruitment-load-test-item>

            <base-pagination>
            </base-pagination>


            <!-- no result -->
            <div class="col-12 text-center no-result no-items">
                <h4 class="mt-4">Search result not found!!</h4>
            </div>
        </div>
    </section>
    <!-- Knowledge base ends -->

</template>

<script>
// import the core functions from vuex

import RecruitmentLoadTestItem from '@/components/recruitment/RecruitmentLoadTestItem.vue';

export default{
    components:{
        RecruitmentLoadTestItem
    }
}


</script>
